function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function bobGetReferrer() {
  //referrer logic
  // take value from utm_referrer url param if defined.
  // otherwise look for ref url param if defined/
  // otherwise default to document.referrer
  var ref = "NULL";
  var utmREF = getParameterByName('utm_referrer');
  if (utmREF === null || utmREF === '') {
    refVal = getParameterByName('ref');
    if ((refVal === null || refVal === '') && document.referrer.indexOf('www.hibob.com') == -1) {
      //console.log('referrer: '+document.referrer);
      ref = document.referrer;
    } else if (refVal != '') {
      ref = refVal;
    }
  } else {
    ref = utmREF;
  }

  if (ref == '' && getCookie('ref')) {
    ref = getCookie('ref');
  }
  return ref;
}

(function ($) {
  let utmUsed = false;
  var utm_referrals = {};
  var paramMappings = {
    'site_source_name': 'utm_source',
    'campaign.name': 'utm_campaign',
    'campaign.id': 'campaignid',
    'adset.name': 'utm_adset',
    'adset.id': 'utm_adsetid',
    'ad.name': 'utm_adname',
    'ad.id': 'utm_adid',
    'placement': 'utm_medium'
  };
  ['utm_creative', 'utm_lp', 'utm_geo', 'utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content', 'utm_referrer', 'gclid', 'msclkid', 'fbclid', 'first_page_seen', 'ref', 'adid', 'ad_id', 'utm_partner', 'utm_agency', 'utm_influencer', 'utm_funnel', 'utm_obj', 'utm_campaignid', 'utm_adgroupid', 'partnerstackkey', 'site_source_name', 'campaign.name', 'campaign.id', 'adset.name', 'adset.id', 'ad.name', 'ad.id', 'placement'].forEach(function (param_name) {
    var val = getParameterByName(param_name);
    //console.log(param_name);
    //console.log(val);
    if (val === null || val === '') return;
    var val = val.replace(/ /g, "_");
    var val = val.replace(/%2B/g, "_");
    var val = val.replace(/\+/g, "_");
    var newParamName = paramMappings[param_name] || param_name;
    utm_referrals[newParamName] = val;
    utmUsed = true;
    //console.log('after change '+param_name);
    //console.log('after change '+val);
  });

  var existingUTM = getCookie('utm_referrals_cookie');
  if (existingUTM) {
    //
    //do nothing
    //
  } else {
    //first page seen
    var currentURL = 'https://' + window.location.hostname + window.location.pathname;
    utm_referrals['first_page_seen'] = currentURL;

    //referrer logic - defined in function bobGetReferrer()
    utm_referrals['utm_referrer'] = bobGetReferrer();

  }

  var utm_content = utm_referrals.utm_content;
  if (jQuery.isEmptyObject(utm_referrals) === false) {
    if (typeof utm_content !== 'undefined') {
      if (utm_content.match("newHP$")) {
        if ($('body').hasClass('page-template-template-home-php') || $('body').hasClass('page-template-template-alternate-homepage-php')) {
          var menu_id = $('.navbarCollapse').attr('data-menu-id');
          var page_link = location.pathname;
          utm_referrals.menu_id = menu_id;
          utm_referrals.page_link = page_link;
        }
      }
    }

    $.ajax({
      type: 'POST',
      url: pw_script_vars.ajaxurl,
      data: {
        action: 'set_utm_referral_cookie',
        utm_referrals: utm_referrals
      },
      success: function (data) {
        //console.log('UTMs are set');
        //console.log(utm_referrals);
        if (data !== '' && utm_content && utm_content.match("newHP$") !== null) {
          $('a.brand').attr('href', location.protocol + '//' + location.host + location.pathname);
        }
      }
    });

  }

  function populateMarketoWithEntryUtmData() {


    BobEntryData = new Object();
    var existingEntryUTM = getCookie('utm_entry');

    if (existingEntryUTM) {

      BobEntryData = JSON.parse(existingEntryUTM);

    } else {
      var currentURL = 'https://' + window.location.hostname + window.location.pathname;
      //referrer logic - defined in function bobGetReferrer()


      var entrySource = getParameterByName('utm_source') || getParameterByName('site_source_name');
      if (entrySource == "") {
        entrySource = "NULL";
      }

      var entryMedium = getParameterByName('utm_medium');
      if (entryMedium == "") {
        entryMedium = "NULL";
      }

      var entryCampaignName = getParameterByName('utm_campaign') || getParameterByName('campaign.name');
      if (entryCampaignName == "") {
        entryCampaignName = "NULL";
      }

      var entryCampaignID = getParameterByName('utm_id');
      if (entryCampaignID == "") {
        entryCampaignID = "NULL";
      }

      var EntryReferrer = bobGetReferrer();
      if (EntryReferrer == "") {
        EntryReferrer = "NULL";
      }

      BobEntryData = {
        EntryPage: currentURL,
        EntryReferrer: EntryReferrer,
        entrySource: entrySource,
        entryMedium: entryMedium,
        entryCampaignName: entryCampaignName,
        entryCampaignID: entryCampaignID
      }

      setCookie("utm_entry", JSON.stringify(BobEntryData), 60);
    }


    if (typeof MktoForms2 !== 'undefined' && Object.keys(BobEntryData)[0] !== 'undefined') {
      MktoForms2.whenReady(function (form) {
        var elm = form.getFormElem();
        if ($(elm).hasClass('mirror-form')) {
          return; //stop old script for the new mirror form
        }

        if (Object.keys(BobEntryData).length === 0) {
          //if entry utm data empty. do nothing.
        } else {
          //populate forms with etry utm data
          form.setValues({
            Entry_Page__c: BobEntryData.EntryPage,
            Entry_Referrer__c: BobEntryData.EntryReferrer,
            Entry_Campaign_Name__c: BobEntryData.entryCampaignName,
            Entry_Campaign_ID__c: BobEntryData.entryCampaignID,
            Entry_Source__c: BobEntryData.entrySource,
            Entry_Medium__c: BobEntryData.entryMedium
          });
        }
      });
    }
  }
  setInterval(populateMarketoWithEntryUtmData, 1000);

  function populateMarketoWithVwoData() {

    //VWO tracking
    //If various experiments exist on same page - the first one found will be used.
    //If various experiments exist in user journey - the last experiment in journey will be stored.
    var existingVwoData = getCookie('bob-vwo-data');
    vwoUUID = "";
    BobVwoData = new Object();
    BobHistoryObject = new Object();

    //first check for history.
    if (existingVwoData) {
      BobExistingVwoData = JSON.parse(existingVwoData);
      BobHistoryObject = BobExistingVwoData.vwoHistory ?? BobHistoryObject;
    }

    if (typeof _vwo_exp !== 'undefined' && typeof _vwo_campaignData !== 'undefined' && typeof Object.keys(_vwo_campaignData)[0] !== 'undefined') {
      if (typeof _vwo_uuid !== 'undefined') {
        var vwoUUID = _vwo_uuid;
      }
      var vwoCampiagnID = Object.keys(_vwo_campaignData)[0]; //experiment ID
      var vwoCampaignObj = _vwo_exp[vwoCampiagnID];
      var vwoCampaignName = vwoCampaignObj.name;
      var vwoVariationChoson = vwoCampaignObj.combination_chosen; //variation ID
      var vwoVariationName = vwoCampaignObj.comb_n[vwoVariationChoson]; //varation name
      var vwoVariationGoalID = Object.keys(vwoCampaignObj.goals)[0]; //goalID
      var vwoVariationType = vwoCampaignObj.type; //goalID

      if (vwoVariationChoson > 0) {

        BobHistoryObject[vwoCampiagnID] = {
          vwoCampiagnID: vwoCampiagnID,
          vwoCampaignName: vwoCampaignName,
          vwoVariationChoson: vwoVariationChoson,
          vwoVariationName: vwoVariationName,
          vwoVariationGoalID: vwoVariationGoalID,
          vwoVariationType: vwoVariationType
        }

        BobVwoData = {
          vwoUUID: vwoUUID,
          vwoCampiagnID: vwoCampiagnID,
          vwoCampaignName: vwoCampaignName,
          vwoVariationChoson: vwoVariationChoson,
          vwoVariationName: vwoVariationName,
          vwoVariationGoalID: vwoVariationGoalID,
          vwoVariationType: vwoVariationType,
          // vwoHistory: BobHistoryObject
        };

        //vwoNewHistoryString = vwoCampiagnID+"~"+vwoCampaignName+"~"+vwoVariationChoson+"~"+vwoVariationName;

        //if (vwoNewHistoryString != vwoExistingHistory)
        //BobVwoData.vwoHistory = vwoExistingHistory+"+"+vwoNewHistoryString;

        setCookie("bob-vwo-data", JSON.stringify(BobVwoData), 60);
      }


    } else if (existingVwoData) {
      //existingVwoCampaignID is experiment ID
      BobVwoData = JSON.parse(existingVwoData);
    }

    // console.log(BobVwoData.vwoUUID);
    // console.log(BobVwoData.vwoCampiagnID);
    // console.log(BobVwoData.vwoCampaignName);
    // console.log(BobVwoData.vwoVariationChoson);
    // console.log(BobVwoData.vwoVariationName);

    let BobVWOHistoryString = "";
    let BobVWOHistoryIndex = 0;
    if (typeof BobVwoData.vwoHistory !== 'undefined') {
      if (typeof Object.keys(BobVwoData.vwoHistory) !== 'undefined') {
        Object.keys(BobVwoData.vwoHistory).forEach(key => {
          if (BobVWOHistoryIndex > 0)
            BobVWOHistoryString += '+';
          BobVWOHistoryString += BobVwoData.vwoHistory[key].vwoCampiagnID + "~" + BobVwoData.vwoHistory[key].vwoCampaignName + "~" + BobVwoData.vwoHistory[key].vwoVariationChoson + "~" + BobVwoData.vwoHistory[key].vwoVariationName;
          //console.log(BobVWOHistoryString);
        });
      }
    }

    if (typeof MktoForms2 !== 'undefined') {
      MktoForms2.whenReady(function (form) {
        var elm = form.getFormElem();
        if ($(elm).hasClass('mirror-form')) {
          return; //stop old script for the new mirror form
        }
        if (Object.keys(BobVwoData).length === 0) {
          //if VWO data empty. do nothing.
        } else {
          //populate forms with vwo data
          form.setValues({
            VWO_UUID__c: BobVwoData.vwoUUID,
            experiment_ID__c: BobVwoData.vwoCampiagnID,
            experiment_name__c: BobVwoData.vwoCampaignName,
            variation_ID__c: BobVwoData.vwoVariationChoson,
            variation_name__c: BobVwoData.vwoVariationName,
            VWO_history__c: BobVWOHistoryString ? BobVWOHistoryString : ''
          });
        }
      });
    }
  }
  setInterval(populateMarketoWithVwoData, 1000);
  function populateMarketoWithMutinyData() {
    if (window.mutiny && window.mutiny.experiences) {
      var existingMutinyData = getCookie('bob-mutiny-data');
      BobMutinyData = new Object();
      BobHistoryObject = new Object();
      var mutinyAccess = window.mutiny

      var mutinyAccessExp = mutinyAccess.experiences[0]
      //first check for history.
      if (existingMutinyData) {
        BobExistingMutinyData = JSON.parse(existingMutinyData);
        BobHistoryObject = BobExistingMutinyData.mutinyHistory;
      }

      if (typeof mutinyAccessExp !== "undefined") {
        var mutinyAccessExpLength = Object.keys(mutinyAccess.experiences).length
        BobHistoryObject = {
          audienceSegment: mutinyAccessExp.audienceSegment,
          experience: mutinyAccessExp.experience,
          impressionType: mutinyAccessExp.impressionType,
          page: mutinyAccessExp.page,
          variationName: mutinyAccessExp.variationName,
          type: mutinyAccess.type
        }

        if (mutinyAccessExpLength > 0) {

          BobHistoryObject[mutinyAccessExpLength] = {
            audienceSegment: mutinyAccessExp.audienceSegment,
            experience: mutinyAccessExp.experience,
            impressionType: mutinyAccessExp.impressionType,
            page: mutinyAccessExp.page,
            variationName: mutinyAccessExp.variationName,
            type: mutinyAccess.type,
          }

          BobMutinyData = {
            audienceSegment: mutinyAccessExp.audienceSegment,
            experience: mutinyAccessExp.experience,
            impressionType: mutinyAccessExp.impressionType,
            page: mutinyAccessExp.page,
            variationName: mutinyAccessExp.variationName,
            type: mutinyAccess.type,
            mutinyHistory: BobHistoryObject
          };
          setCookie("bob-mutiny-data", JSON.stringify(BobMutinyData), 60);
        }

      } else if (existingMutinyData) {
        BobMutinyData = JSON.parse(existingMutinyData);
      }

      BobMutinyHistoryString = "";
      BobVMutinyistoryIndex = 0;
      if (typeof mutinyAccessExp !== "undefined") {
        var mutinyAccessExpLength = Object.keys(mutinyAccess.experiences).length
        Object.keys(mutinyAccessExpLength).forEach(function () {
          if (BobVMutinyistoryIndex > 0)
            BobMutinyHistoryString += '+';
          BobMutinyHistoryString += BobMutinyData.audienceSegment + "~" + BobMutinyData.experience + "~" + BobMutinyData.impressionType + "~" + BobMutinyData.page;
        });
      }

      if (typeof MktoForms2 !== 'undefined') {
        MktoForms2.whenReady(function (form) {
          var elm = form.getFormElem();
          if ($(elm).hasClass('mirror-form')) {
            return; //stop old script for the new mirror form
          }
          if (Object.keys(BobMutinyData).length === 0) {
          } else {
            form.setValues({
              mutiny_audience_segment__c: BobMutinyData.audienceSegment,
              mutiny_experience_name__c: BobMutinyData.experience,
              mutiny_impression_type__c: BobMutinyData.impressionType,
              mutiny_page__c: BobMutinyData.page,
              mutiny_variation_name__c: BobMutinyData.variationName
            });
          }
        });
      }
    }
  }
  setInterval(populateMarketoWithMutinyData, 1000);
})(jQuery);

